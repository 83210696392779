import * as React from "react"
import { graphql, useStaticQuery } from "gatsby"
//import { StaticImage } from "gatsby-plugin-image"
//import { stripHtml } from "string-strip-html"

import Layout from "../components/layout"
import Seo from "../components/seo"
import * as styles from "../components/index.module.css"

const Test = () => {
  const wpdata = useStaticQuery(graphql`
    query MyQuery {
      allWpPage(filter: { slug: { eq: "test-page" } }) {
        nodes {
          slug
          title
          content
        }
      }
    }
  `)

  return (
    <Layout>
      <Seo title="Test" />
      <div
        style={{
          padding: "100px",
          paddingTop: "200px",
          backgroundColor: "#bbb",
        }}
      >
        <div className={styles.textCenter}></div>
        <ul className={styles.list}>
          {wpdata.allWpPage.nodes.map(page => (
            <li key={page.slug} className={styles.listItem}>
              <h2>{page.title}</h2>
              <div dangerouslySetInnerHTML={{ __html: page.content }} />
            </li>
          ))}
        </ul>
      </div>
    </Layout>
  )
}

/**
 * Head export to define metadata for the page
 *
 * See: https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-head/
 */
export const Head = () => <Seo title="Test" />

export default Test
